<template>
  <q-list>
    <q-item
      href="https://www.google.com/maps/dir/?q=place_id:ChIJfwecWCBSaIgRguIjuoPha40"
      target="_blank"
    >
      <q-item-section>
        <q-item-label class="text-subtitle2">
          KYGUNCO Bardstown
        </q-item-label>
        <q-item-label class="text-grey-5">
          401 Glenwood Drive, Bardstown, KY 40004
        </q-item-label>
        <q-item-label caption>
          Mon - Sat: 9am - 6pm
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-avatar
          :icon="mdiMapMarker"
          font-size="30px"
          text-color="grey"
          square
        />
      </q-item-section>
    </q-item>
    <q-item
      href="https://www.google.com/maps/dir/?q=place_id:ChIJu1yzo5WZaYgRU5lrq2lQNhI"
      target="_blank"
    >
      <q-item-section>
        <q-item-label class="text-subtitle2">
          KYGUNCO Louisville
        </q-item-label>
        <q-item-label class="text-grey-5">
          2301 Nelson Miller Pkwy, Louisville, KY 40223
        </q-item-label>
        <q-item-label caption>
          Mon - Sat: 9am - 6pm
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-avatar
          :icon="mdiMapMarker"
          font-size="30px"
          text-color="grey"
          square
        />
      </q-item-section>
    </q-item>
    <q-item
      href="tel:(502) 348-3594"
      target="_blank"
    >
      <q-item-section>
        <q-item-label class="text-subtitle2">
          Call Center
        </q-item-label>
        <q-item-label class="text-grey-5">
          (502) 348-3594
        </q-item-label>
        <q-item-label caption>
          Mon - Sat: 9am - 5pm
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-avatar
          :icon="mdiHeadset"
          font-size="30px"
          text-color="grey"
          square
        />
      </q-item-section>
    </q-item>
    <q-item
      href="https://range.kygunco.com"
      target="_blank"
    >
      <q-item-section>
        <q-item-label class="text-subtitle2">
          Indoor Range
        </q-item-label>
        <q-item-label caption>
          Experience the Ultimate Shooting Thrill at KYGUNCO's Premier Indoor
          Range!
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-avatar
          :icon="mdiTarget"
          font-size="30px"
          text-color="grey"
          square
        />
      </q-item-section>
    </q-item>
    <q-item to="/bow-tech">
      <q-item-section>
        <q-item-label class="text-subtitle2">
          Bow Tech
        </q-item-label>
        <q-item-label caption>
          Trust KYGUNCO's Skilled Bow Techs for Professional Maintenance,
          Repairs, and Upgrades
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-avatar
          :icon="mdiBowArrow"
          font-size="30px"
          text-color="grey"
          square
        />
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script lang="ts" setup>
import {
  mdiMapMarker,
  mdiHeadset,
  mdiTarget,
  mdiBowArrow,
} from '@quasar/extras/mdi-v7';
</script>
