<template>
  <q-drawer
    v-model="navStore.visible"
    overlay
    behavior="mobile"
  >
    <q-scroll-area class="fit">
      <q-list v-if="status == 'pending'">
        <q-item
          v-for="l in 9"
          :key="l"
        >
          <q-item-section>
            <q-item-label>
              <q-skeleton type="rect" />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list v-else-if="data?.items?.length">
        <NavDrawerItem
          v-for="(item, index) in data.items"
          :key="index"
          :item="item"
        />
      </q-list>

      <q-item
        clickable
        @click="$q.dialog({ component: LazyDialogFirearmFinder })"
      >
        <q-item-section>
          <q-item-label class="text-h6">
            Firearm Finder
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-icon
            :name="mdiOpenInApp"
            size="sm"
          />
        </q-item-section>
      </q-item>

      <q-expansion-item
        label="Site Links"
        class="text-h6"
        :content-inset-level="0.15"
      >
        <q-list class="bg-dark">
          <q-item to="/blog">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Blog
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/videos">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Videos
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/brands">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Brands
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/dealers">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Find Your FFL
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/page/privacy-policy">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Privacy Policy
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/page/shipping-policy">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Shipping Policy
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/page/return-policy">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Return Policy
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/page/terms-and-conditions">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Terms and Conditions
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/contact">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                Contact Us
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/about">
            <q-item-section>
              <q-item-label class="text-subtitle2">
                About Us
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-expansion-item>
    </q-scroll-area>
  </q-drawer>
</template>

<script lang="ts" setup>
import { mdiOpenInApp } from '@quasar/extras/mdi-v7';
import { whenever } from '@vueuse/core';
import { LazyDialogFirearmFinder } from '#components';
import type { Menu } from '~/types/content';

const navStore = useNavStore();
const $q = useQuasar();

const { data, status, execute } = await useAsyncData(
  'menus_navigation',
  () => queryContent<Menu>('menus', 'navigation').findOne(),
  {
    immediate: false,
    getCachedData: getCachedPayload,
  },
);

whenever(() => navStore.visible && !data.value, () => execute());
</script>

<style lang="scss" scoped>
// :deep(.q-expansion-item__content) {
//   background: $primary;
// }

:deep(.q-router-link--active) {
  background: $primary;
  color: $grey-3;
}
</style>
