import { acceptHMRUpdate, defineStore } from 'pinia';

export const useNavStore = defineStore('nav', () => {
  const visible = ref(false);

  const toggle = () => (visible.value = !visible.value);

  return {
    visible,
    toggle,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNavStore, import.meta.hot));
}
