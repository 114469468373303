<template>
  <div class="column justify-between full-height">
    <div class="q-mb-lg">
      <q-img
        src="/logos/kygunco.svg"
        placeholder-src="/logos/kygunco.svg"
        ratio="9.07"
        width="300px"
        height="60px"
        fit="contain"
        class="q-mb-sm"
      />
      <div class="text-body1 text-grey-3 q-mb-lg">
        KYGUNCO is your professional and dependable firearms retailer.
        We operate two awesome retail stores in central Kentucky in conjunction
        with KYGUNCO.com. Over the last 78 years we have strived to make purchasing
        firearms simple for the law-abiding citizen. Our customers love our no-nonsense
        way of doing business. For the customer this means fast and free shipping on
        thousands of firearms at a competitive price, but without the hidden fees,
        credit card fees, club memberships and the gimmicky pricing tricks other retailers
        try. Straight Foward-Simple-Honest, that is how we've been doing it since 1946,
        and we don't plan on changing.
      </div>

      <q-btn
        to="/about"
        label="Learn More"
        color="primary"
        square
      />
    </div>

    <div>
      <div class="row justify-between items-center q-gutter-md">
        <div>
          <div class="row q-gutter-x-md">
            <q-btn
              v-for="link in links"
              :key="link.url"
              :href="link.url"
              :title="`Find us on ${link.name}!`"
              :icon="link.icon"
              target="_blank"
              padding="sm"
              size="md"
              flat
              square
            />
          </div>
        </div>

        <div>
          <div class="row flex-center q-gutter-x-sm">
            <q-avatar
              color="grey-8"
              style="width: 45px; height: 32px"
              rounded
            >
              <img
                src="/brands/visa.svg"
                height="32"
                width="32"
                loading="lazy"
              >
            </q-avatar>

            <q-avatar
              color="grey-8"
              style="width: 45px; height: 32px"
              rounded
            >
              <img
                src="/brands/mastercard.svg"
                height="32"
                width="32"
                loading="lazy"
              >
            </q-avatar>

            <q-avatar
              color="grey-8"
              style="width: 45px; height: 32px"
              rounded
            >
              <img
                src="/brands/discover.svg"
                height="32"
                width="32"
                loading="lazy"
              >
            </q-avatar>

            <img
              src="/brands/credova.svg"
              height="30"
              width="30"
              loading="lazy"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  siRumble,
  siYoutube,
  siFacebook,
  siX,
  siInstagram,
} from 'simple-icons';

const links = [
  {
    name: 'Rumble',
    url: 'https://rumble.com/c/KYGUNCO',
    icon: `${siRumble.path}@@fill:#${siRumble.hex}`,
    caption:
      'Join KYGUNCO on Rumble for Heart-Pounding Shooting Action, Expert Advice, and Deep-Dive Product Reviews!',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCSpG73Z1l3Wwu7GW7R31R6w',
    icon: `${siYoutube.path}@@fill:#${siYoutube.hex}`,
    caption:
      'Explore KYGUNCO\'s YouTube Channel for Expert Reviews, Shooting Tips, and Unbeatable Deals!',
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/kentuckygunco',
    icon: `${siFacebook.path}@@fill:#${siFacebook.hex}`,
    caption:
      'Join KYGUNCO\'s Thriving Facebook Community for Exclusive Offers, Expert Advice, and the Latest Firearm Trends!',
  },
  {
    name: 'X',
    url: 'https://twitter.com/kygunco',
    icon: `${siX.path}@@fill:#ffffff`,
    caption:
      'Follow KYGUNCO on X for the Latest Firearms, Top Brands, and Unbeatable Deals!',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/kygunco',
    icon: `${siInstagram.path}@@fill:#${siInstagram.hex}`,
    caption:
      'Fire up Your Feed with KYGUNCO\'s Instagram for Exclusive Sneak Peeks, Thrilling Shooting Videos, and Unbeatable Deals!',
  },
];
</script>
